import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import LinkMui from '@mui/material/Link'
import LogoText from '@UI/sharedComponents/LogoText'
import { useTranslations } from 'next-intl'
import React from 'react'

import type { NavigationElement } from '../../types'
import NavItem from './_components/NavItem'

const { Link } = getLivSharedPathnamesNavigation()

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void
  open: boolean
  variant: 'permanent' | 'persistent' | 'temporary' | undefined
  pages: NavigationElement[]
}

const Sidebar = ({ pages, open, variant, onClose }: Props): JSX.Element => {
  const t = useTranslations('OldMenu')
  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 256,
          top: { xs: 0, md: 71 },
          height: { xs: '100%', md: 'calc(100% - 71px)' },
        },
      }}
    >
      <Box marginY={2} marginLeft={2}>
        <LinkMui
          href="/"
          underline="none"
          component={Link}
          onClick={() => onClose()}
        >
          <LogoText variant="h3" component="p" />
        </LinkMui>
      </Box>
      {pages.map((p) => (
        <Box key={p.id} marginY={2} marginLeft={2}>
          {!p.option.children ? (
            <LinkMui
              component={Link}
              href={p.option.href}
              color="text.primary"
              underline="none"
              onClick={() => onClose()}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {/* @ts-expect-error titles come from navigation.ts */}
              {t(p.title)}
            </LinkMui>
          ) : (
            <NavItem title={p.title} items={p.option.children} />
          )}
        </Box>
      ))}
    </Drawer>
  )
}

export default Sidebar
