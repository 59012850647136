import type { NavigationElement } from './types'

const pages: NavigationElement[] = [
  {
    id: 'products-investigations',
    title: 'Investigations',
    option: {
      href: '/products/investigations/',
    },
  },
  {
    id: 'products-contracts',
    title: 'Contracts',
    option: {
      href: '/products/contracts/',
    },
  },
  {
    id: 'products-legal-policy',
    title: 'LegalPolicy',
    option: {
      href: '/products/legal-policy/',
    },
  },
]

export default pages
