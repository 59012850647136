'use client'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Menu, MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import { alpha, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTranslations } from 'next-intl'
import React, { useEffect, useState } from 'react'

type NavigationOption =
  | { href: string; children?: never }
  | { href?: never; children: NavigationElement[] }

type NavigationElement = {
  id: string
  title: string
  option: NavigationOption
  isNew?: boolean
}

interface Props {
  title: string
  id: string
  items: NavigationElement[]
}

const { Link } = getLivSharedPathnamesNavigation()

const NavItem = ({ title, id, items }: Props): JSX.Element => {
  const theme = useTheme()
  const t = useTranslations('OldMenu')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null)

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    popoverId: string,
  ): void => {
    setAnchorEl(event.currentTarget)
    setOpenedPopoverId(popoverId)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const [activeLink, setActiveLink] = useState('')
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '')
  }, [])

  const hasActiveLink = () => items.find((i) => i.option.href === activeLink)
  const linkColor = 'text.primary'

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        aria-describedby={id}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => handleClick(e, id)}
      >
        <Typography
          fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
          color={linkColor}
        >
          {title}
        </Typography>
        <ExpandMoreIcon
          sx={{
            marginLeft: theme.spacing(1 / 4),
            width: 16,
            height: 16,
            transform: openedPopoverId === id ? 'rotate(180deg)' : 'none',
            color: linkColor,
          }}
        />
      </Box>
      <Menu
        id="account-menu"
        onClick={handleClose}
        open={openedPopoverId === id}
        onClose={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          '.MuiPaper-root': {
            padding: 1,
            marginTop: 1,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        {items.map((p) => (
          <MenuItem
            key={p.id}
            component={Link}
            href={p.option.href!}
            sx={{
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
              },
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Typography variant="body2" color="inherit">
              {p.title}
            </Typography>
            {p.isNew ? (
              <Box
                padding={0.5}
                display="inline-flex"
                borderRadius={1}
                bgcolor="primary.main"
                marginLeft={2}
              >
                <Typography
                  variant="caption"
                  sx={{ color: 'common.white', lineHeight: 1 }}
                >
                  {t('New')}
                </Typography>
              </Box>
            ) : null}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default NavItem
