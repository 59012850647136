import { useAuth } from '@clerk/nextjs'
import Header from '@layout/Header'
import React from 'react'

export interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const { userId } = useAuth()

  return (
    <>
      <Header isLoggedIn={!!userId} />
      {children}
    </>
  )
}

export default MainLayout
