import type { LivRentOperationPOGetResponse } from '@api-client/livBackend.schemas'
import useRentOperationByUUID from 'hooks/rentOperations/useRentOperationByUUID'
import React, { createContext, useContext, useMemo } from 'react'

type ProviderProps = {
  uuid: string | null
  children: React.ReactNode
}

const RentOperationContext = createContext<{
  rentOperation?: LivRentOperationPOGetResponse
}>({})

export const RentOperationProvider = ({ uuid, children }: ProviderProps) => {
  const { rentOperation } = useRentOperationByUUID(uuid?.toString() ?? '')
  const value = useMemo(() => ({ rentOperation }), [rentOperation])

  return (
    <RentOperationContext.Provider value={value}>
      {children}
    </RentOperationContext.Provider>
  )
}

export function useRentOperation() {
  const context = useContext(RentOperationContext)
  if (context === undefined) {
    throw new Error(
      'useRentOperation must be used within a RentOperationProvider',
    )
  }
  return context
}
