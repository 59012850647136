import 'dayjs/locale/es-mx'
import 'dayjs/locale/en'

import type { Localization } from '@mui/material/locale'
import {
  enUS as materialLocaleEnglish,
  esES as materialLocaleSpanish,
} from '@mui/material/locale'

export interface MUILocaleData {
  muiCore: Localization
  code: string
  dayJSLanguage: string
}

const spanish: MUILocaleData = {
  muiCore: materialLocaleSpanish,
  code: 'es-MX',
  dayJSLanguage: 'es-mx',
}

const english: MUILocaleData = {
  muiCore: materialLocaleEnglish,
  code: 'en',
  dayJSLanguage: 'en',
}

export const supportedLocales: MUILocaleData[] = [spanish, english]
