export enum AnalyticsEvents {
  // Ensure these values aren't used in the backend !!!
  InvestigationDetailsViewed = 'WebInvestigationDetailsViewed',
  InvestigationInitialViewed = 'WebInvestigationInitialViewed',
  InvestigationSubjectAdded = 'WebInvestigationSubjectAdded',
  InvestigationSubjectEdited = 'WebInvestigationSubjectEdited',
  InvestigationCreated = 'WebInvestigationCreated',
  InvestigationSetupCompleted = 'InvestigationSetupCompleted', // TODO: Add Web prefix, need to sync with Hiatus for FB event.
  RentOperationContractCoverViewed = 'WebRentOperationContractCoverViewed',
  RentOperationContractApproved = 'WebRentOperationContractApproved',
  RentOperationDetailsViewed = 'WebRentOperationDetailsViewed',
  RentOperationCollaboratorAdded = 'WebRentOperationCollaboratorAdded',
  RentOperationCollaboratorEdited = 'WebRentOperationCollaboratorEdited',
  RentOperationCreated = 'WebRentOperationCreated',
  RentOperationPropertyOwnerAdded = 'WebRentOperationPropertyOwnerAdded',
  RentOperationPropertyOwnerEdited = 'WebRentOperationPropertyOwnerEdited',
  RentOperationPropertyOwnerDeleted = 'WebRentOperationPropertyOwnerDeleted',
  RentOperationPropertyDetailsEdited = 'WebRentOperationPropertyDetailsEdited',
  RentOperationRentDetailsAdded = 'WebRentOperationRentDetailsAdded',
  RentOperationRentDetailsEdited = 'WebRentOperationRentDetailsEdited',
  RentOperationRentTermsAdded = 'WebRentOperationRentTermsAdded',
  RentOperationRentTermsEdited = 'WebRentOperationRentTermsEdited',
  InvestigationSubjectDataCompleted = 'WebInvestigationSubjectDataCompleted',
  UserProfileChanged = 'WebUserProfileChanged',
}
