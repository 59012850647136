'use client'

import type { ComponentsOverrides } from '@mui/material/styles'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import { light } from './Palette'
import shadows from './Shadows'

export const maxWidth = 1200
export const mode = 'light'

const theme = responsiveFontSizes(
  createTheme({
    shadows: shadows(mode),
    breakpoints: {
      values: {
        xs: 0,
        sm: 640, // small tablet
        md: 860, // large tablet
        lg: 1080, // old laptops
        xl: maxWidth, // desktop and new laptops
      },
    },
    palette: {
      ...light,
      alternate: {
        main: '#f7faff',
        dark: '#edf1f7',
      },
    },
    typography: {
      fontFamily: ['Lato', 'Arial', 'sans-serif'].join(','),
      subtitle1: {
        lineHeight: '29.75px',
        letterSpacing: '.15px',
      },
      subtitle2: {
        lineHeight: '23.55px',
        letterSpacing: '0.1px',
      },
      body1: {
        letterSpacing: '.15px',
      },
      body2: {
        lineHeight: '21.45px',
        letterSpacing: '.17px',
      },
      caption: {
        lineHeight: '21.58px',
        letterSpacing: '.4px',
      },
    },
    components: {
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: { color: 'white' },
        } as ComponentsOverrides['MuiButton'],
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        } as ComponentsOverrides['MuiInputBase'],
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        } as ComponentsOverrides['MuiOutlinedInput'],
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        } as ComponentsOverrides['MuiCard'],
      },
    },
  }),
)

export default theme
