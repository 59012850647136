import { Typography, type TypographyProps } from '@mui/material'
import { Nunito } from 'next/font/google'

const nunito = Nunito({
  subsets: ['latin'],
  display: 'swap',
})

const LogoText = (props: TypographyProps) => {
  return (
    <Typography
      {...props}
      fontFamily={nunito.style.fontFamily}
      color="#44c805"
      display="inline"
      fontWeight="700"
    >
      liv
    </Typography>
  )
}

export default LogoText
