import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

type NavigationOption =
  | { href: string; children?: never }
  | { href?: never; children: NavigationElement[] }

type NavigationElement = {
  id: string
  title: string
  option: NavigationOption
  isNew?: boolean
}

interface Props {
  title: string
  items: NavigationElement[]
}

const NavItem = ({ title, items }: Props): JSX.Element => {
  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        sx={{ backgroundColor: 'transparent', p: 0 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: 0,
            minHeight: 0,
            '& .MuiAccordionSummary-content': { m: 0 },
          }}
        >
          <Typography
            color="text.primary"
            sx={{
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: 1,
            pl: 2,
            borderLeft: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Stack spacing={1.5}>
            {items.map((p) => (
              <Link
                key={p.id}
                href={p.option.href}
                underline="none"
                color="text.primary"
                sx={{
                  '&:hover': {
                    color: 'primary.main',
                  },
                }}
              >
                {p.title}
              </Link>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default NavItem
